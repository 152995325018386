import ExportedImage from 'next-image-export-optimizer';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import DecentralizationSpectrum from '@/components/shared/lp/home/DecentralizationSpectrum';

import fullyDecentralized from '@/public/images/lp/home/whykomodo/fully-decentralized-1.png';
import openSource from '@/public/images/lp/home/whykomodo/open-source-1.png';
import permissionLess from '@/public/images/lp/home/whykomodo/permission-less.png';
import poweredByKomodoCoin from '@/public/images/lp/home/whykomodo/powered-by-komodo-coin-1.png';
import regularUpdates from '@/public/images/lp/home/whykomodo/regular-updates-1.png';
import securityRobustness from '@/public/images/lp/home/whykomodo/security-robustness-1.png';
import trackRecord from '@/public/images/lp/home/whykomodo/track-record-1.png';

const GreenIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className='h-6 w-6'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  );
};

const data = [
  {
    title: 'Powered by Komodo Coin',
    description:
      'For KMD trading pairs, 25% of the DEX transaction fees goes towards automatically burning KMD. Market takers receive a 10% trading fee discount when selling KMD for another cryptocurrency.',
  },
  {
    title: 'Security & Robustness',
    description:
      'Komodo Wallet is based on the most robust and secure technology stack and underwent thorough security assessments.',
  },
  {
    title: 'Open Source',
    description:
      'Validate don&apos;t trust! All of our code and technology is open source and publicly available.',
  },
  {
    title: 'Permissionless',
    description:
      'Komodo Wallet is permissionless, non-custodial and decentralized and thus doesn&apos;t require any user-side KYC. We respect your privacy.',
  },
  {
    title: 'Track Record',
    description:
      'With roots going back to 2014, Komodo is the market leader in peer-to-peer (P2P) atomic swap technology. Our team continues to deliver innovation that benefits the entire crypto space — from developers and holders, to traders and projects.',
  },
  {
    title: 'Regular Updates',
    description:
      'Our team is consistently adding support for more cryptocurrencies and implementing new features to create a first-class (d)experience.',
  },
];

const DeFiTechnology = () => {
  return (
    <section className='my-20 text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='text-center text-base font-normal leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
          WHY Komodo?
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.25}>
        <div className='mt-[10px] text-center'>
          <h3 className='mx-auto mb-[46px] max-w-[756px] font-heading text-3xl font-semibold text-white sm:text-4xl lg:text-5xl'>
            Use the most robust Wallet and secure DeFi technology
          </h3>
          <div className='flex flex-col items-center justify-center gap-[18.72px]'>
            <div className='flex flex-col items-center gap-[28.72px] lg:flex-row'>
              <div className='relative h-[475.54px] w-[576.6px] overflow-hidden rounded-[26.211px] bg-[#0F1922] -sm:h-[460px] -sm:w-[300px]'>
                <ExportedImage
                  className='mx-auto h-auto w-full'
                  src={poweredByKomodoCoin}
                  alt='Powered by Komodo Coin'
                />
                <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-b from-gray-900/85 to-[#0f1922] px-[37.44px] pb-[35px] text-left lg:pb-[37.5px]'>
                  <h2 className='pt-[30px] font-heading text-[29.95px] font-semibold leading-[36px] md:leading-[43.06px] -sm:text-[20px]'>
                    Powered by Komodo Coin
                  </h2>
                  <div className='pt-[4.68px] text-[16.85px] font-normal leading-[26.2px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                    For KMD trading pairs, 25% of the DEX transaction fees goes towards
                    automatically burning KMD. Market takers receive a 10% trading fee discount when
                    selling KMD for another cryptocurrency.
                  </div>
                </div>
              </div>

              <div className='relative flex flex-col items-center gap-[16.85px]'>
                <div className='relative flex h-[229.345px] w-[576.6px] flex-row rounded-[26.211px] bg-[#0F1922] -sm:h-[350px] -sm:w-[300px] -sm:flex-col'>
                  <ExportedImage
                    className='top-[-16.37px] h-auto w-[390px] sm:absolute sm:left-[-70px] -sm:left-[60px] -sm:top-[30px]'
                    src={securityRobustness}
                    alt='Security & Robustness'
                  />
                  <div className='absolute ml-auto w-[294px] text-left sm:right-[27.15px] sm:top-[47.74px] sm:mb-[47.1px] -sm:bottom-[20px] -sm:px-[37.44px]'>
                    <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:text-[20px] -sm:leading-[26px]'>
                      Security & Robustness
                    </h4>
                    <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                      Komodo Wallet is based on the most robust and secure technology stack and
                      underwent thorough security assessments.
                    </p>
                  </div>
                </div>
                <div className='relative flex w-[576.6px] flex-row items-center rounded-[26.211px] bg-[#0F1922] sm:h-[229.345px] -sm:w-[300px] -sm:flex-col'>
                  <div className='left-[27.15px] top-[59.91px] ml-auto w-[294px] text-left sm:absolute sm:mb-[47.1px] -sm:order-2 -sm:px-[37.44px] -sm:pb-[27.44px]'>
                    <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:text-[20px] -sm:leading-[26px]'>
                      Open Source
                    </h4>
                    <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                      Validate don&apos;t trust! Our code and technology is open source and publicly
                      available.
                    </p>
                  </div>
                  <ExportedImage
                    className='right-[13.2px] top-[7.66px] h-auto w-[285px] sm:absolute -sm:order-1'
                    src={openSource}
                    alt='Opensource'
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-[28.72px] lg:flex-row'>
              <div className='flex flex-col items-center gap-[18.72px]'>
                <div className='flex flex-col gap-[16.85px]'>
                  <div className='relative flex w-[576.6px] flex-row rounded-[26.211px] bg-[#0F1922] sm:h-[229.345px] -sm:w-[300px] -sm:flex-col'>
                    <div className='left-[27.15px] top-[59.91px] ml-auto w-[294px] text-left sm:absolute sm:mb-[47.74px] -sm:order-2 -sm:px-[37.44px] -sm:pb-[27.44px]'>
                      <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:-mt-[20px] -sm:text-[20px] -sm:leading-[26px]'>
                        Permissionless
                      </h4>
                      <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                        Komodo Wallet is permission less, non-custodial and decentralized and thus
                        doesn&apos;t require any user-side KYC. We respect your privacy.
                      </p>
                    </div>
                    <ExportedImage
                      className='bottom-[0px] right-[3.74px] h-auto w-[250.87px] sm:absolute -sm:order-1 -sm:mx-auto'
                      src={permissionLess}
                      alt='Permissionless'
                    />
                  </div>
                  <div className='relative flex w-[576.6px] flex-row rounded-[26.211px] bg-[#0F1922] sm:h-[343.55px] -sm:w-[300px] -sm:flex-col'>
                    <ExportedImage
                      className='left-[10.4px] top-[5.55px] h-auto w-[235.7px] sm:absolute -sm:mx-auto'
                      src={fullyDecentralized}
                      alt='Fully non-custodial & decentralized'
                    />
                    <div className='right-[27.15px] top-[44px] ml-auto w-[294px] text-left sm:absolute sm:mb-[47.1px] -sm:px-[37.44px] -sm:pb-[27.44px]'>
                      <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:text-[20px] -sm:leading-[26px]'>
                        Fully Decentralized
                      </h4>
                      <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                        Komodo Wallet is a fully non-custodial decentralized exchange (DEX) that
                        uses P2P orderbooks and atomic swaps to facilitate trading. This means that
                        you can trade directly from your wallet without the need for a centralized
                        intermediary. You always maintain 100% control of your funds.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col items-center gap-[18.72px]'>
                <div className='relative flex w-[576.6px] flex-row rounded-[26.211px] bg-[#0F1922] sm:h-[334.188px] -sm:w-[300px] -sm:flex-col'>
                  <ExportedImage
                    className='left-[-37.16px] top-[48.37px] h-auto w-[330px] sm:absolute -sm:mx-auto -sm:pt-10'
                    src={trackRecord}
                    alt='Fully non-custodial & decentralized'
                  />
                  <div className='right-[27.15px] top-[51.49px] ml-auto w-[294px] text-left sm:absolute sm:mb-[47.1px] -sm:px-[37.44px] -sm:pb-[27.44px]'>
                    <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:text-[20px] -sm:leading-[26px]'>
                      Track Record
                    </h4>
                    <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                      With roots going back to 2014, Komodo is the market leader in peer-to-peer
                      (P2P) atomic swap technology. Our team continues to deliver innovation that
                      benefits the entire crypto space — from developers and holders, to traders and
                      projects.
                    </p>
                  </div>
                </div>
                <div className='relative flex w-[576.64px] flex-row rounded-[26.211px] bg-[#0F1922] sm:h-[238.7px] -sm:w-[300px] -sm:flex-col'>
                  <ExportedImage
                    className='left-[-13.32px] top-[5.11px] h-auto w-[290.16px] sm:absolute -sm:mx-auto'
                    src={regularUpdates}
                    alt='Regular Updates'
                  />
                  <div className='right-[27.15px] top-[52.42px] ml-auto w-[294px] text-left sm:absolute sm:mb-[47.1px] -sm:px-[37.44px] -sm:pb-[27.44px]'>
                    <h4 className='font-heading text-[23.4px] font-semibold leading-[26.11px] -sm:text-[20px] -sm:leading-[26px]'>
                      Regular Updates
                    </h4>
                    <p className='pt-[9.51px] text-[16.85px] font-normal leading-[24.33px] text-white -sm:text-[14px] -sm:leading-[20px]'>
                      Our team is consistently adding support for more cryptocurrencies and
                      implementing new features to create a first-class (d)experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='grid gap-x-16 lg:grid-cols-2 xl:grid-cols-3'>
            {data.map(({ title, description }, index) => {
              return (
                <AnimateWhileInView delay={index / 5} key={title}>
                  <div className='flex items-start justify-start gap-4 mt-16 gap-y-6'>
                    <div>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='w-[24px] h-[24px]'
                      >
                        <circle cx='12' cy='12' r='12' fill='#5a68e6A1' />
                        <circle cx='12' cy='12' r='7' fill='#5a68e6' />
                      </svg>
                    </div>
                    <div>
                      <h2 className='text-2xl font-heading'>{title}</h2>
                      <p className='mt-2 leading-7 text-gray-300'>{description}</p>
                    </div>
                  </div>
                </AnimateWhileInView>
              );
            })}
            <AnimateWhileInView delay={0.7}>
              <div className='flex items-start justify-start gap-4 mt-16 gap-y-6'>
                <div>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='w-[24px] h-[24px]'
                  >
                    <circle cx='12' cy='12' r='12' fill='#5a68e6A1' />
                    <circle cx='12' cy='12' r='7' fill='#5a68e6' />
                  </svg>
                </div>
                <div>
                  <h2 className='text-2xl font-heading'>Fully Decentralized</h2>
                  <p className='mt-2 leading-7 text-gray-300'>
                    Komodo Wallet is a fully non-custodial decentralized exchange (DEX) that uses
                    P2P orderbooks and atomic swaps to facilitate trading. This means that you can
                    trade directly from your wallet without the need for a centralized intermediary.
                    You always maintain 100% control of your funds.
                  </p>
                </div>
              </div>
            </AnimateWhileInView>
          </div> */}
        </div>
      </AnimateWhileInView>
      {/* <AnimateWhileInView delay={0.9}>
          <DecentralizationSpectrum />
        </AnimateWhileInView> */}
    </section>
  );
};

export default DeFiTechnology;
