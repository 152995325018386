import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import { IndexBlogCard } from '@/components/shared/lp/IndexBlogCard';

import KomoDeFi_DeFi from '@/public/images/lp/home/komodefi/KomoDeFi_DeFi.png';
import KomoDeFiDEX from '@/public/images/lp/home/komodefi/KomoDeFi_Exchange.png';
import KomoDeFiWallet from '@/public/images/lp/home/komodefi/KomoDeFi_Wallet.png';

const LatestNews = ({ blogPosts }: { blogPosts: any }) => {
  return (
    <section className='flex flex-col my-20 text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='font-normal -lg:leading-[17px] leading-[20px] tracking-widest -lg:text-sm text-base text-[#60A5FA] text-center'>
          News
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.25}>
        <div className='mt-[10px] text-center'>
          <h3 className='max-w-[756px] mx-auto font-semibold font-heading text-3xl sm:text-4xl lg:text-5xl text-white -sm:mb-0 mb-[46px]'>
            Latest News
          </h3>
          <div className='flex flex-col items-center my-3 space-y-8 md:my-6 xl:my-12 lg:flex-row lg:items-stretch lg:space-y-0 lg:space-x-[18px] lg:mb-0'>
            {blogPosts.map((post: any, index: any) => (
              <IndexBlogCard
                key={index}
                index={index}
                feature_image_info={post.feature_image_info}
                date={post.readable_published_at}
                dateIso={post.iso_published_at}
                title={post.title}
                description={post.meta_description.substring(0, 150) + ' ...'}
                href={post.permalink}
                tagName={post.tagName}
                readingTime={post.readingTime}
              />
            ))}
          </div>
        </div>
      </AnimateWhileInView>
    </section>
  );
};

export default LatestNews;
