import { useRef } from 'react';
import { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import { wrap } from '@motionone/utils';
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
} from 'framer-motion';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import BeInCrypto from '@/public/images/lp/home/featured/be_in_crypto.svg';
import BenzingaCrypto from '@/public/images/lp/home/featured/benzinga_crypto.svg';
import CoinJournal from '@/public/images/lp/home/featured/coin_journal.svg';
import CoinTelegraphLogo from '@/public/images/lp/home/featured/coin_telegraph_logo.svg';
import CoindeskLogo from '@/public/images/lp/home/featured/coindesk_logo.svg';
import CryptoNews from '@/public/images/lp/home/featured/crypto_news.svg';
import ForbesLogo from '@/public/images/lp/home/featured/forbes_logo.svg';
import Hackernoon from '@/public/images/lp/home/featured/hackernoon.png';
import MarketsInsider from '@/public/images/lp/home/featured/markets_insider.svg';
import Medium from '@/public/images/lp/home/featured/medium.svg';
import NasdaqLogo from '@/public/images/lp/home/featured/nasdaq_logo.svg';
import TheFintechTimes from '@/public/images/lp/home/featured/the_fintech_times.svg';
import YahooFinance from '@/public/images/lp/home/featured/yahoo_finance.svg';

interface ParallaxProps {
  children: any;
  baseVelocity: number;
}
const publications = [
  {
    name: 'Coin Journal',
    image: CoinJournal,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Crypto News',
    image: CryptoNews,
    classNaming: 'h-12 w-auto',
  },
  {
    name: 'Be In Crypto',
    image: BeInCrypto,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Benzinga Crypto',
    image: BenzingaCrypto,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Forbes',
    image: ForbesLogo,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Nasdaq',
    image: NasdaqLogo,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Coindesk',
    image: CoindeskLogo,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Coin Telegraph',
    image: CoinTelegraphLogo,
    classNaming: 'h-8 w-[204px] sm:h-12 w-auto',
  },
  {
    name: 'Hackernoon',
    image: Hackernoon,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Markets Insider',
    image: MarketsInsider,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Medium',
    image: Medium,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'The Fintech Times',
    image: TheFintechTimes,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
  {
    name: 'Yahoo Finance',
    image: YahooFinance,
    classNaming: 'h-8 sm:h-12 w-auto',
  },
];

function ParallaxText({ children, baseVelocity = 100 }: ParallaxProps) {
  const [width, setWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (innerRef.current && containerRef.current) {
      setWidth(innerRef.current.offsetWidth);
    }
  }, []);

  useAnimationFrame((t, delta) => {
    if (containerRef.current && innerRef.current) {
      containerRef.current.scrollLeft += baseVelocity * (delta / 1000);

      if (containerRef.current.scrollLeft >= width) {
        containerRef.current.scrollLeft -= width;
      }
    }
  });

  return (
    <div className='overflow-hidden' ref={containerRef}>
      <div className='flex flex-nowrap items-center gap-[60px] py-4' ref={innerRef}>
        {children}
        {children}
        {children}
        {children}
        {children}
      </div>
    </div>
  );
}

export default function AsFeaturedOn() {
  return (
    <section className='mx-auto my-20 flex flex-col text-center text-white lg:my-24 2xl:my-36'>
      <AnimateWhileInView delay={0.2}>
        <h2 className='leading-1 mb-12 font-heading text-3xl font-semibold sm:text-4xl lg:text-[49.496px]'>
          As featured on
        </h2>
      </AnimateWhileInView>
      <AnimateWhileInView delay={0.25}>
        <div className='overflow-hidden'>
          <div className='flex w-max animate-scroll flex-row flex-nowrap items-center gap-[60px] py-4'>
            {[...publications, ...publications, ...publications, ...publications].map(
              (publication, index) => (
                <ExportedImage
                  key={index}
                  src={publication.image}
                  alt={publication.name}
                  className={publication.classNaming}
                />
              ),
            )}
          </div>
        </div>
      </AnimateWhileInView>
    </section>
  );
}

// {<section className="flex flex-col mx-auto my-20 text-white lg:my-32 2xl:my-48 -lg:text-center">
// <AnimateWhileInView delay={0.2}>
//   <h2 className="mb-12 text-white uppercase text-5xl -lg:text-4xl leading-[4rem] font-heading font-semibold">
//     As featured on
//   </h2>
// </AnimateWhileInView>
// <AnimateWhileInView delay={0.25}>
//   <div className="flex flex-col items-center justify-around gap-4 space-y-8 sm:items-start sm:grid sm:grid-cols-2 xl:justify-between xl:flex xl:flex-row">
//     <AnimateWhileInView delay={0.1}>
//       <Link
//         href="https://www.forbes.com/sites/lukefitzpatrick/2019/09/02/a-complete-beginners-guide-to-atomic-swaps/"
//         className="flex flex-col space-y-4 max-w-[16rem] sm:mt-8 mx-auto"
//       >
//         <img
//           src="/images/lp/home/featured/forbes_logo.svg"
//           alt="Forbes Logo"
//           className="h-12"
//         />
//         <p>
//           Komodo, an atomic swap early adopter launched the first atomic
//           swap across ethereum. Today, Komodo has facilitated atomic swaps
//           across 95 percent of all cryptocurrencies.
//         </p>
//       </Link>
//     </AnimateWhileInView>
//     <AnimateWhileInView delay={0.2}>
//       <Link
//         href="https://www.nasdaq.com/articles/decentralized-exchange-launches-for-dogecoin-swaps-2021-03-10"
//         className="flex flex-col space-y-4 max-w-[16rem] mx-auto"
//       >
//         <img
//           src="/images/lp/home/featured/nasdaq_logo.svg"
//           alt="Nasdaq Logo"
//           className="h-12"
//         />
//         <p>Decentralized Exchange Launches for Dogecoin Swaps.</p>
//       </Link>
//     </AnimateWhileInView>
//     <AnimateWhileInView delay={0.3}>
//       <Link
//         href="https://www.coindesk.com/business/2022/11/30/blockchain-protocol-komodo-introduces-three-in-one-wallet-cross-chain-bridge-and-dex/"
//         className="flex flex-col space-y-4 max-w-[16rem] mx-auto"
//       >
//         <img
//           src="/images/lp/home/featured/coindesk_logo.svg"
//           alt="Forbes"
//           className="h-12"
//         />
//         <p>
//           Blockchain Protocol Komodo Offering Three-In-One Wallet,
//           Cross-Chain Bridge and DEX.
//         </p>
//       </Link>
//     </AnimateWhileInView>
//     <AnimateWhileInView delay={0.4}>
//       <Link
//         href="https://cointelegraph.com/news/komodo-to-introduce-12-blockchain-protocols-in-the-next-version-of-atomicdex"
//         className="flex flex-col space-y-4 max-w-[16rem] mx-auto"
//       >
//         <img
//           src="/images/lp/home/featured/coin_telegraph_logo.svg"
//           alt="Forbes"
//           className="w-[204px] h-12 mx-10"
//         />
//         <p>
//           Komodo to introduce 12 blockchain protocols in the next version
//           of the App.
//         </p>
//       </Link>
//     </AnimateWhileInView>
//   </div>
// </AnimateWhileInView>
// </section>}
