import React, { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';

import { ArrowDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import platform from 'platform';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';
import SwapWidget from '@/components/shared/SwapWidget';

import { siteData } from '@/data/site';
import gradientLinkArrow from '@/public/images/lp/svgs/gradient-link-arrow.svg';

const Hero = () => {
  const [flutterSwapWidget, setFlutterSwapWidget] = useState(true);
  useEffect(() => {
    const osFamily = platform.os?.family;

    if (osFamily == 'Android' || osFamily == 'iOS') {
      setFlutterSwapWidget(false);
    }
  }, []);
  return (
    <section
      className='relative z-[12] flex flex-col items-center justify-between pt-24 md:pt-36 lg:min-h-screen lg:flex-row lg:pt-12'
      id='hero-section'
    >
      <div className='flex flex-col space-y-5 md:w-[80%] lg:w-2/4 3xl:space-y-7 -lg:mx-auto -lg:mb-16 -lg:items-center -lg:text-center'>
        <AnimateWhileInView>
          <span className='text-sm text-blue-400 xl:text-base'>REAL WEB3 EMPOWERMENT</span>
        </AnimateWhileInView>
        <h1 className='flex flex-col font-heading text-5xl font-semibold md:leading-[4rem] lg:text-6xl lg:leading-[4rem] 2xl:text-[73px] 2xl:leading-none 3xl:text-8xl'>
          <AnimateWhileInView delay={0.2}>
            <span>Join THE</span>
          </AnimateWhileInView>
          <AnimateWhileInView delay={0.3}>
            <span className='trustless-gradient font-medium'>trustless</span>
          </AnimateWhileInView>
          <AnimateWhileInView delay={0.4}>
            <span>(d)experience</span>
          </AnimateWhileInView>
        </h1>
        <AnimateWhileInView delay={0.5}>
          <p className='text-lg text-gray-200'>
            Eliminate the middleman and take real control of what you own. Introducing Komodo Wallet
            — a Wallet, DEX and Bridge all-in-one platform compatible with{' '}
            <span className='listed-crypto'> 99%</span> of crypto assets.
          </p>
        </AnimateWhileInView>

        <AnimateWhileInView delay={0.6}>
          <div className='mx-auto flex flex-wrap justify-start gap-4 pt-4 xs:gap-6 sm:flex-nowrap md:justify-center md:pt-6 lg:justify-start xl:px-0 3xl:pt-10'>
            <motion.a href={siteData.webDEXlink} className='' target='_blank'>
              <motion.button
                type='button'
                className={`hero-btn justify-left relative flex w-[160px] flex-row items-center overflow-hidden rounded-[14px] border border-none px-3 py-2 font-sans text-base font-bold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                whileHover={{
                  y: -4,
                  boxShadow: '0 4px 15px rgba(84, 104, 230, 0.6)',
                  transition: { duration: 0.2 },
                }}
                whileTap={{ scale: 0.95 }}
              >
                <motion.span>Launch Wallet</motion.span>
                <ExportedImage
                  src={gradientLinkArrow}
                  className='h-[19px] w-auto pl-[7px]'
                  width={16}
                  height={17}
                  alt='link'
                />
              </motion.button>
            </motion.a>
          </div>
        </AnimateWhileInView>
      </div>
      <AnimateWhileInView delay={0.7}>
        <div className='relative isolate overflow-hidden'>
          {/* <div className="absolute -z-50 left-1/2 -translate-x-1/2 w-1/2 md:w-[26rem] lg:w-[20rem] h-40 lg:h-96 bg-gradient-to-r from-sky-500/20 via-sky-100/10 to-sky-100/20 rounded-full blur-3xl opacity-40"></div> */}
          <div
            onClick={() => {
              window.scrollBy({ behavior: 'smooth', top: 600 });
            }}
            className='bg-primary absolute right-1 top-2 z-50 hidden rounded-[1.125rem] p-2 transition hover:cursor-pointer -sm:block'
          >
            <div>
              <ArrowDownIcon className='animate-bounce h-5 w-5 pt-1' />
            </div>
          </div>
          {flutterSwapWidget ? (
            <iframe
              title='Swap Widget'
              className='h-[550px] w-full rounded-[1.125rem] border-none xs:w-[430px]'
              src={siteData.swapWidgetUrl}
            />
          ) : (
            ''
          )}
        </div>
      </AnimateWhileInView>
    </section>
  );
};

// (
//   <SwapWidget />
// )

export default Hero;
