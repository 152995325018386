import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

export const IndexBlogCard = (params) => {
  return (
    <AnimateWhileInView
      key={params.index}
      delay={0.2 + 0.1 * parseInt(params.index)}
      classNaming='relative w-full flex flex-col text-left bg-[#0E1E2B] mx-auto lg:mx-0 md:w-[480px] lg:w-full my-8 lg:my-0 rounded-[14px] blog-card transition-all duration-300 hover:-translate-y-4'
    >
      <Link href={params.href} aria-label={params.title}>
        <ExportedImage
          loading='lazy'
          width={params.feature_image_info.width}
          height={params.feature_image_info.height}
          src={params.feature_image_info.url}
          alt={params.feature_image_info.alt || params.title || 'Image on Komodo Platform'}
          className='object-cover w-full lg:h-[150px] xl:h-[187px] 2xl:h-[224px] 3xl:h-[281] rounded-t-[18px] shadow-sm'
        />
      </Link>
      <Link href={params.href} className=''>
        <div className='px-4 mt-4'>
          <p className='text-base leading-[20px] text-[#60A5FA] mt-2'>{params.tagName}</p>
          <h2 className='mt-2 text-base font-semibold font-heading leading-1'>{params.title}</h2>
          <p className='text-sm my-2 leading-[19px] font-normal'>{params.description}</p>
        </div>
      </Link>
      <div className='w-full h-[45px]'></div>
      <div className='absolute bottom-0 flex flex-row justify-between w-full px-4 pb-4'>
        <Link href={params.href}>
          <p className='text-sm leading-[28px] font-bold'>{params.readingTime}</p>
        </Link>
        <Link href={params.href}>
          <p className='text-sm leading-[28px]  font-bold'>
            <time dateTime={params.dateIso}>{params.date}</time>
          </p>
        </Link>
      </div>
    </AnimateWhileInView>
  );
};
