import ExportedImage from 'next-image-export-optimizer';

import { motion } from 'framer-motion';

import AnimateWhileInView from '@/components/shared/AnimateWhileInView';

import { siteData } from '@/data/site';
import swap from '@/public/images/lp/home/swap.png';
import trustless from '@/public/images/lp/home/trustless.png';
import gradientLinkArrow from '@/public/images/lp/svgs/gradient-link-arrow.svg';

const Trustless = () => {
  return (
    <section className='mx-auto my-10 flex items-center justify-between gap-x-[97px] text-white lg:my-24 lg:w-full 2xl:my-36 -lg:flex-col-reverse'>
      <AnimateWhileInView delay={0.3} classNaming='w-full sm:w-[80%] lg:w-1/2 -lg:mx-auto'>
        <ExportedImage
          className='h-auto w-full'
          src={trustless}
          alt='Widest blockchain protocol support'
        />
      </AnimateWhileInView>
      <div className='flex w-2/4 flex-col space-y-4 -lg:mb-12 -lg:w-[80%] -lg:items-center -lg:text-center -sm:w-auto'>
        <AnimateWhileInView delay={0.1}>
          <h2 className='text-base font-normal uppercase leading-[20px] tracking-widest text-[#60A5FA] -lg:text-sm -lg:leading-[17px]'>
            Trustless
          </h2>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.15}>
          <h3 className='font-heading text-3xl font-semibold sm:text-4xl lg:text-5xl'>
            Widest Blockchain Protocol Support
          </h3>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.2}>
          <p className='text-xl leading-10 -lg:text-lg'>
            Trade any supported assets across various protocols in a fully non-custodial and
            decentralized manner. Be your own exchange!
          </p>
        </AnimateWhileInView>
        <AnimateWhileInView delay={0.25}>
          <motion.a
            target='_blank'
            href='/en/docs/komodo-defi-framework/tutorials/listing-a-new-coin/'
            className='trustless-gradient flex h-14 flex-row items-center rounded-md text-xl font-bold leading-10 underline -lg:flex-col -lg:text-lg'
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: 'spring', stiffness: 400, damping: 17 }}
          >
            List your coin/token on
            <span className='ml-1 flex flex-row items-center'>
              {' '}
              Komodo Wallet
              <ExportedImage
                src={gradientLinkArrow}
                className='ml-2 h-[19px] w-auto'
                width={16}
                height={17}
                alt='link'
              />
            </span>
          </motion.a>
        </AnimateWhileInView>
      </div>
    </section>
  );
};

export default Trustless;
